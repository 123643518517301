<template>
  <div>
    <div class="my-5">
      <span>
        <label for="username">{{ $t('forms.username') }}</label>
        <InputText id="username" type="text" v-model="username" @change="onChange('username', username)" />
      </span>
    </div>
    <div class="my-5">
      <span>
        <label for="email">{{ $t('forms.email') }}</label>
        <InputText id="email" type="text" v-model="email" disabled/>
      </span>
    </div>
    <div class="my-5">
      <span>
        <label for="isBlocked">{{ $t('forms.userBlocked') }}</label>
        <InputSwitch v-model="isBlocked" @change="onChange('isBlocked', isBlocked)" />
      </span>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import { mapActions, mapGetters } from 'vuex'
import Log from '@/services/logger'

export default {
  components: {
    InputText,
    InputSwitch
  },
  data: () => ({
    username: '',
    email: '',
    isBlocked: false
  }),
  watch: {
    'user': function (user) {
      if (user) {
        this.username = user.username
        this.email = user.email
        this.isBlocked = user.isBlocked
      }
    }
  },
  computed: {
    ...mapGetters('adminUser', ['user', 'hasUpdate'])
  },
  methods: {
    ...mapActions('adminUser', ['update']),
    onChange (field, value) {
      this.update({field, value})
    }
  },
  mounted () {
    Log(this.user)
  }
}
</script>

<style scoped>
label {
  margin: 0.5em 0;
  display: block;
  font-weight:bold;
}
</style>