<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :draggable="false">
      <template #header>
        <h3>New User</h3>
      </template>

      <form class="p-fluid" @keyup.enter="signin" >
        <div class="p-fluid">
          <div class="p-field my-5">
            <div class="p-float-label">
              <InputText id="username"
              v-model="username"
              :class="{'p-invalid': usernameError}"
              :disabled="isLoading"/>
              <label for="username" :class="{'p-error':usernameError}">{{ $t('forms.username') }}</label>
            </div>
            <small v-if="usernameError" class="p-error">{{usernameError}}</small>
          </div>
          <div class="p-field p-my-5">
            <div class="p-float-label">
              <InputText id="email"
              v-model="email"
              :class="{'p-invalid': emailError}"
              :disabled="isLoading"/>
              <label for="email" :class="{'p-error':emailError}">{{ $t('forms.email') }}</label>
            </div>
            <small v-if="emailError" class="p-error">{{emailError}}</small>
          </div>
        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
        <Button :label="$t('actions.create')" icon="pi pi-check" @click="create"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Message from "primevue/message"

import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Log from '@/services/logger'


export default {
  setup: () => ({ v$: useVuelidate() }),
  props: ['modelValue'],
  components: { InputText, Message },

  data: () => ({
    username: '',
    email: '',
    showIt: false,
    isLoading: false,
    errorCaught: ''
  }),

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.username = ''
      this.email = ''
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.$pxstream
      .createOneUser({username: this.username, email: this.email})
      .then(({data}) => {
        this.$router.push(`/admin/users/${data.id}`)
      })
      .catch(err => {
        this.errorCaught = 'Failed to create user. Please, try again later'
        Log.Error(err)
      })
    }
  },

  computed: {
    usernameError () {
      if (!this.v$.username.$dirty) return ''
      if (this.v$.username.required.$invalid) return 'Username is required.'
      return ''
    },
    emailError () {
      if (!this.v$.email.$dirty) return ''
      if (this.v$.email.required.$invalid) return 'Email is required.'
      if (this.v$.email.email.$invalid) return 'Wrong email format.'
      return ''
    },
  },

  validations: {
    username: {
      required,
      // isUnique (val) {
      //   if (value === '') return true
      // }
    },
    email: {
      required,
      email
    }
  },

  watch: {
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>